<script>
import Layout from "@/views/layouts/main.vue";
import appConfig from "@/app.config";
import { mapGetters,mapActions } from 'vuex';
import TelegramBotEdit from "./telegram/bot-edit"
import Swal from 'sweetalert2'

export default {
  page: {
    title: "Bots",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    TelegramBotEdit
  },
  data() {
    return {
      pagetitle: "Bots",
      initializing: true,
      editTelegramBotId: null
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters('auth', {
      currentUser: 'currentUser'
    }),
    ...mapGetters('bots', {
      bots: 'bots',
      botsLoading: 'loading',
      bitsDeleting: "deleting"
    }),
    isBusy() {
      return this.botsLoading || this.initializing;
    }   
  },
  methods:{
    ...mapActions('bots', {
      loadBots: 'loadBots',
      deleteTelegramBot: 'deleteTelegramBot'
    }),
    async init() {
      this.initializing = true;
      await this.loadBots();
      this.initializing = false;
    },
    tryToDelete(id, m) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          m({id: id}).then(()=>{
            Swal.fire("Deleted!", "Bot has been deleted.", "success");
          });
        }
      });
    }
  }
};
</script>

<template>
  <Layout :pagetitle="pagetitle" :is-busy="isBusy">
    <telegram-bot-edit v-if="editTelegramBotId" @close="editTelegramBotId = null" :id="editTelegramBotId" />

    <div class="row align-items-center">
      <div class="col-md-6"><div><h4 class="card-title"><i class="mdi mdi-telegram me-2"></i> Telegram Bots</h4></div></div>
      <div class="col-md-6 text-end">
        <button 
          :disabled="!currentUser.isAdmin"
          class="btn btn-primary waves-effect waves-light btn-sm"
          @click="editTelegramBotId = 'new'"
        >
          <i class="mdi mdi-telegram me-1"/>
          Register a Telegram Bot
        </button>                    
      </div> 
    </div>
    <div class="table-responsive">
      <table
        class="table project-list-table table-nowrap align-middle table-borderless"
      >
        <thead>
          <tr>
            <th scope="col">Bot Name</th>
            <th scope="col">Disabled</th>
            <th scope="col" style="width: 120px" v-if="currentUser.isAdmin">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="bots.telegram.length === 0">
            <td colspan="100">
              <em class="text-muted">No bots found...</em>
            </td>
          </tr>
          <tr v-for="b in bots.telegram" :key="b.id">
            <td>
              <a v-clipboard:copy="b.botName" href="javascript:void(0)" class="me-2">
                <i class="mdi-content-copy mdi"/>
              </a>
              {{b.botName}}
            </td>
            <td>
              <b v-if="b.disabled">
                disabled
              </b>
            </td>
            <td v-if="currentUser.isAdmin">
              <ul class="list-inline mb-0">
                
                <li class="list-inline-item" v-if="!b.deletedAt">
                  <a href="javascript:void(0)" 
                    class="px-2 text-primary"  
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Edit"
                    @click="editTelegramBotId = b.id"
                  >
                    <i class="bx bx-pencil font-size-18"></i>
                  </a>
                </li>
                <li class="list-inline-item" v-if="!b.deletedAt">
                  <a
                    @click="tryToDelete(b.id, deleteTelegramBot)"
                    href="javascript:void(0);"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Delete"
                    class="px-2 text-danger"
                    >
                    <i class="bx bx-trash-alt font-size-18" v-if="bitsDeleting !== b.id"></i>
                    <i class="fa fa-spin fa-spinner font-size-18" v-if="bitsDeleting === b.id"></i>
                  </a>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </Layout>
</template>
